
























































































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { NeedTraining } from '@/api/interfaces/needtraining'
import TopBar from '@/components/organisms/o-top-bar.vue'
import store from '@/store'
import { QueryParams } from '@/store/api-plateform-utils'
import { usePagination, PaginationOptions } from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import { RawLocation } from 'vue-router'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { TrainingType } from '@/api/interfaces/trainingtype'

export default defineComponent({
  setup (props, ctx) {
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(() => {
    return ctx.root.$store.state.traineeList.totalItems
  }) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
    }
  },
  name: 'ListTraineesWithNoTrainingSession',
  components: {
    TopBar,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    ListSelectFilter: () => import('@/components/base/ListSelectFilter.vue'),
    Pagination,
  },
  data () {
    return {
      filters: {
        lastNameSearched: '',
        firstNameSearched: '',
        trainingTypeSearched: '',
        businessIntroducerSearched: '',
        companySearched: '',
        branchSearched: '',
        areaSearched: '',
      },
      title: this.$t(
        'need_training_associate_training_session_list.list.title_header',
      ),
      headers: [
        {
          value: 'checkbox',
          sortable: false,
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.lastName',
          ),
          align: 'start',
          value: 'trainee.lastName',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.firstName',
          ),
          value: 'trainee.firstName',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.trainingType',
          ),
          value: 'trainingType.name',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.phoneNumber',
          ),
          value: 'trainee.phoneNumber',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.email',
          ),
          value: 'trainee.email',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.company.businessIntroducer',
          ),
          value: 'businessIntroducer.name',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.company.name',
          ),
          value: 'company.name',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.company.parentCompany',
          ),
          value: 'branch.name',
        },
        {
          text: this.$t(
            'need_training_associate_training_session_list.list.headers.area',
          ),
          value: 'trainee.area.name',
          sortable: false,
        },
      ],
      isSubmiting: false,
      checkedNeedTrainings: [] as string[],
      loadedNeedTrainings: new Map<string, NeedTraining>(),
    }
  },
  computed: {
    ...mapState('traineeList', {
      associateNeedTrainingSessionList: 'list',
      totalItems: 'totalItems',
    }),
    ...mapState('company', {
      listCompanies: 'list',
    }),
    ...mapState('logisticSessionForm', {
      logisticSession: 'logisticSession',
    }),
    ...mapState('area', {
      listAreas: 'list',
    }),
    ...mapGetters('navigation', {
      getLastRouteLogisticSession: 'getLastRouteLogisticSession',
    }),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        'trainee.lastName': this.filters.lastNameSearched,
        'trainee.firstName': this.filters.firstNameSearched,
        trainingType: this.trainingSessionType ? this.trainingSessionType['@id'] : null,
        'businessIntroducer.name': this.filters.businessIntroducerSearched,
        company: this.filters.companySearched,
        'trainee.area': this.filters.areaSearched,
        'branch.name': this.filters.branchSearched,
        'exists[trainingSession]': false,
        'status.code': (this as any).$appConfig.needTrainingStatusesCodesToPlanifie,
        ...this.paginationRequestParams,
      }

      return request as QueryParams
    },
    idLogisticSession (): string | null {
      return this.$route.params.idLogisticSession || null
    },
    idTrainingSession (): string | null {
      return this.$route.params.idTrainingSession || null
    },
    trainingSession (): TrainingSession {
      return this.logisticSession.trainingSessions.find((t: TrainingSession) => (t.id as number).toString() === this.idTrainingSession)
    },
    trainingSessionType (): TrainingType | null {
      const trainingSession = this.trainingSession
      return trainingSession ? (trainingSession.trainingType as TrainingType) : null
    },
  },
  created () {
    if (this.getLastRouteLogisticSession === null) {
      const route: RawLocation = {
        name: 'LogisticSession Edit',
        params: {
          idLogisticSession: this.idLogisticSession as string,
        },
      }
      this.$router.push(route)

      return
    }

    this.checkedNeedTrainings = (this.trainingSession as TrainingSession).needTrainings.map(
      (n: NeedTraining) => n['@id'],
    ) as Array<string>
    this.trainingSession.needTrainings.forEach((n: NeedTraining) => {
      this.loadedNeedTrainings.set(n['@id'] as string, n)
    })
    this.load(this.baseRequest).then(this.afterLoad)
  },
  watch: {
    paginationOptions: {
      handler () {
        this.load(this.baseRequest).then(this.afterLoad)
      },
      deep: true,
    },
    filters: {
      handler () {
        this.paginationOptions.page = 1
        this.load(this.baseRequest).then(this.afterLoad)
      },
      deep: true,
    },
  },
  methods: {
    afterLoad (loadedList: NeedTraining[]) {
      loadedList.forEach((n: NeedTraining) => {
        this.loadedNeedTrainings.set(n['@id'] as string, n)
      })
    },
    ...mapActions('traineeList', {
      load: 'load',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    ...mapMutations('logisticSessionForm', {
      setNeedTrainingsForTrainingSession: 'setNeedTrainingsForTrainingSession',
      setCurrentAssociationWithIdTrainingSession: 'setCurrentAssociationWithIdTrainingSession',
    }),
    associateCheckedNeedTrainingsToTrainingSession () {
      this.isSubmiting = true

      const needTrainings = [] as NeedTraining[]
      this.checkedNeedTrainings.forEach((needTrainingIri: string): void => {
        if (this.loadedNeedTrainings.has(needTrainingIri)) {
          needTrainings.push(
          this.loadedNeedTrainings.get(needTrainingIri) as NeedTraining,
          )
        }
      })
      this.setNeedTrainingsForTrainingSession({
        idTrainingSession: parseInt(this.idTrainingSession as string),
        needTrainings: needTrainings,
      })

      this.$router.push(this.getLastRouteLogisticSession)
    },
    filterByLastName (value: string) {
      this.filters.lastNameSearched = value
    },
    filterByfirstName (value: string) {
      this.filters.firstNameSearched = value
    },
    filterByBusinessIntroducer (value: string) {
      this.filters.businessIntroducerSearched = value
    },
    filterByCompany (value: string) {
      this.filters.companySearched = value
    },
    filterByBranch (value: string) {
      this.filters.branchSearched = value
    },
    filterByArea (value: string) {
      this.filters.areaSearched = value
    },
    goToNeedTrainingForm () {
      this.setCurrentAssociationWithIdTrainingSession(parseInt(this.idTrainingSession as string))

      this.$router.push({
        name: 'Trainee Create',
        params: {
          trainingType: ((this.trainingSessionType as TrainingType).id as number).toString(),
        },
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('logisticSessionForm/setCurrentAssociationWithIdTrainingSession', null)
    store.commit('navigation/addRoute', to)
    next()
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'Trainee Create') {
      store.commit('navigation/removeRoute')
    }

    next()
  },
})
